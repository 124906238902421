<template>
  <div class="wrapper">
    <img class="logo-not-found"
         src="~@/assets/404.svg">
    <h1 class="text-style">Упс, страница не найдена</h1>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
  .text-style {
    color: #000;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .logo-not-found {
    width: 195.502px;
    height: 195.5px;
    flex-shrink: 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
  }
  @media (max-width: 767px) {
    .text-style {
      font-size: 16px;
    }
  }

</style>